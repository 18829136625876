export const defaultShopObject = () => ({
  released: false,
  unreleasable: true,
  release_date: null,
  reservation_start_date: null,
  code: null,
  name: null,
  furigana_name: null,
  accept_drop_off: false,
  description: null,
  companies_inventory_group_id: null,
  companies_car_type_ids: []
})
